import { ColorModes, ColorModeTypes, LocalStorageKeys } from 'common';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { ColorModeData } from 'modules/auth/dtos';
import React, { useEffect, useMemo, useState } from 'react';
import { useUserData } from 'utils';

import AppContext from './appContext';

dayjs.extend(CustomParseFormat);
dayjs.extend(isBetween);

export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { settings: { colorMode } = {} } = useUserData();
  const [darkMode, setDarkMode] = useState(false);

  const previousColorMode = useMemo(() => {
    const previousColorModeData = localStorage.getItem(LocalStorageKeys.COLOR_MODE_SETTINGS) ?? '';

    try {
      return JSON.parse(previousColorModeData);
    } catch (e) {
      return undefined;
    }
  }, []);

  useEffect(() => {
    const currentMode = colorMode ?? previousColorMode;

    if (!currentMode) return;

    if (colorMode) {
      localStorage.setItem(LocalStorageKeys.COLOR_MODE_SETTINGS, JSON.stringify(colorMode));
    }

    let timer: NodeJS.Timeout | undefined;
    const { type, default: defaultColorMode, data: colorModeData } = currentMode;

    if (type !== ColorModeTypes.MANUAL) {
      const { lightFrom, lightTo, darkFrom } =
        type === ColorModeTypes.AUTOMATIC ? defaultColorMode : (colorModeData as ColorModeData);

      const lightModeIncludesMidnight = lightFrom > lightTo;
      let isLightMode = false;

      const now = dayjs();
      const parsedLightFrom = dayjs(lightFrom, 'HH:mm');
      const parsedLightTo = dayjs(lightTo, 'HH:mm');

      if (lightModeIncludesMidnight) {
        const midnight = dayjs('00:00', 'HH:mm');
        isLightMode =
          now.isBetween(parsedLightFrom, midnight, 'minutes') &&
          now.isBetween(midnight, parsedLightTo, 'minutes');
      } else {
        isLightMode = now.isBetween(parsedLightFrom, parsedLightTo, 'minutes');
      }

      setDarkMode(!isLightMode);

      const timeDifference = dayjs(isLightMode ? darkFrom : lightFrom, 'HH:mm').diff(dayjs(), 'ms');

      if (timeDifference > 0) {
        timer = setTimeout(() => {
          setDarkMode(current => !current);
        }, timeDifference);
      }

      return;
    }

    setDarkMode(colorModeData === ColorModes.DARK);

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [colorMode, previousColorMode]);

  const contextValue = useMemo(
    () => ({
      darkMode,
    }),
    [darkMode],
  );

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};
