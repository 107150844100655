import DriverStatusToggler from 'modules/driver/components/DriverStatusToggler';
import { statusElementType, StatusStateType } from 'modules/driver/dtos';
import { getStatusList, updateStatus } from 'modules/driver/queries';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

const StatusManagement = () => {
  const { data: status, isFetching: isStatusFetching } = useQuery(getStatusList());
  const { mutate } = useMutation(updateStatus());

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [statusList, setStatusList] = useState<statusElementType[]>(status?.data || []);

  useEffect(() => {
    if (!status) return;

    setStatusList(status?.data);
  }, [status]);

  /**
   * This function is used to handle status item click
   * It updates the status of the selected item
   * @param e
   */
  const handleStatusItemClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setStatusList((prevState: statusElementType[]) => {
      const updatedStatusList = prevState.map(status => {
        if (e.target instanceof HTMLElement && status.id === Number(e.target.id)) {
          return { ...status, state: 1 as StatusStateType };
        }
        return { ...status, state: 0 as StatusStateType };
      });
      return updatedStatusList;
    });
    setIsEditable(true);
  };

  /**
   * This function is used to handle toggle status
   * Changes switch state of the selected item
   * @param id
   */
  const handleToggle = (id: number | string) => {
    setStatusList((prevState: statusElementType[]) => {
      const updatedStatusList = prevState.map(status => {
        if (typeof id === 'number' && id === status.id) {
          return { ...status, state: 1 as StatusStateType };
        }
        return { ...status, state: 0 as StatusStateType };
      });
      return updatedStatusList;
    });
    setIsEditable(true);
  };

  return (
    <DriverStatusToggler
      handleConfirm={() => mutate({ data: statusList })}
      handleStatusItemClick={handleStatusItemClick}
      statusList={statusList}
      isStatusFetching={isStatusFetching}
      handleToggle={handleToggle}
      isEditable={isEditable}
    />
  );
};

export default StatusManagement;
