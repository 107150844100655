import { INTEGRATION_SAMSUNG } from 'common';
import Button from 'components/Button';
import { Status } from 'components/common';
import ConfirmationModal from 'components/ConfirmationModal';
import IconButton from 'components/IconButton';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'components/Table';
import { Buttons, ListWrapper, WrapperCenter } from 'modules/reports/pages/ReportsList';
import moment from 'moment';
import { routes } from 'navigation/routes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDelete, MdOutlineAdd, MdRemove } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useToast } from 'toast';

import { updateIntegrationStatusObject } from '../../api/requests';
import {
  IntegrationObject,
  IntegrationObjectStatus,
} from '../../dtos/IntegrationObjectResponse.dto';
import { deleteIntegrationObject, getIntegrationObjects } from '../../queries';
import { Chip, TableWrapper } from './style';
import { Columns, DataProps, initialModalState, ModalState } from './types';

/* eslint-disable @typescript-eslint/no-use-before-define */

const prepareStatus = (status: IntegrationObjectStatus) => (status === 1 ? 'active' : 'inactive');

const SamsungIntegrationList = () => {
  const { data: integrationObjects, isFetching } = useQuery(getIntegrationObjects());
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showToast } = useToast();

  const [modals, setModals] = useState<ModalState>(initialModalState);
  const [objectIdToBeMutated, setObjectIdToBeMutated] = useState<string>('');

  const queryClient = useQueryClient();
  const deleteObject = useMutation(deleteIntegrationObject()).mutate;
  const updateObjectStatus = useMutation(updateIntegrationStatusObject).mutate;

  const getUpdateMutationParams = (modalName: keyof ModalState) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(INTEGRATION_SAMSUNG);
      showToast({ content: t('createIntegrationObjectSuccess') });
    },
    onError: () => {
      showToast({ content: t('createIntegrationObjectFailure'), type: Status.Negative });
    },
    onSettled: () => {
      modalHandler(modalName).close();
    },
  });

  const getDeleteQueryParams = (modalName: keyof ModalState) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(INTEGRATION_SAMSUNG);
      showToast({ content: t('deleteObjectSuccess') });
      modalHandler(modalName).close();
    },
    onError: () => showToast({ content: t('deleteReportFailure'), type: Status.Negative }),
  });

  const prepareConfirmHandler = (modalName: keyof ModalState, id: string) => {
    switch (modalName) {
      case 'activateObject':
        return updateObjectStatus({ id: id, status: 1 }, getUpdateMutationParams(modalName));
      case 'deactivateObject':
        return updateObjectStatus({ id: id, status: 0 }, getUpdateMutationParams(modalName));
      case 'removeObject':
        return deleteObject({ objectId: id }, getDeleteQueryParams(modalName));
      default:
        return '';
    }
  };

  const prepareModalMsg = (modalName: keyof ModalState) => {
    switch (modalName) {
      case 'activateObject':
        return t('confirmActivateIntegrationObject');
      case 'deactivateObject':
        return t('confirmDeactivateIntegrationObject');
      case 'removeObject':
        return t('confirmRemoveIntegrationObject');
      default:
        return '';
    }
  };

  const prepareConfirmText = (modalName: keyof ModalState) => {
    switch (modalName) {
      case 'activateObject':
        return t('activate');
      case 'deactivateObject':
        return t('deactivate');
      case 'removeObject':
        return t('delete');
      default:
        return '';
    }
  };

  const modalHandler = (modalName: keyof ModalState, id?: string) => ({
    isOpen: modals[modalName],
    open: () => setModals({ ...modals, [modalName]: true }),
    close: () => setModals({ ...modals, [modalName]: false }),
    message: prepareModalMsg(modalName),
    confirmText: prepareConfirmText(modalName),
    onConfirm: () => id && prepareConfirmHandler(modalName, id),
  });

  const integrationObjectsData = integrationObjects?.data?.map((obj: IntegrationObject) => ({
    id: obj.id,
    vrn: obj.args.vrn,
    integrationDate: moment.unix(obj.createdAt).format('YYYY-MM-DD hh:mm:ss'),
    status: t(prepareStatus(obj.status)),
    actions: obj,
  }));

  const handleIconClick = (modalName: keyof ModalState, id: string) => {
    modalHandler(modalName, id).open();
    setObjectIdToBeMutated(id);
  };

  const columns = [
    {
      id: Columns.VRN,
      Header: t('vrn'),
      accessor: Columns.VRN as const,
      Cell: ({ cell: { value } }: DataProps) => <div>{value}</div>,
    },
    {
      id: Columns.INTEGRATIONDATE,
      Header: t('integrationDate'),
      accessor: Columns.INTEGRATIONDATE as const,
      Cell: ({ cell: { value } }: DataProps) => <div>{value}</div>,
    },
    {
      id: Columns.STATUS,
      Header: t('status'),
      accessor: Columns.STATUS as const,
      Cell: ({ cell: { value }, row }: DataProps) => (
        <Chip status={row.original.actions.status}>{value}</Chip>
      ),
    },
    {
      id: Columns.ACTIONS,
      Header: t('actions'),
      accessor: Columns.ACTIONS as const,
      Cell: ({ row }: DataProps) => (
        <Buttons>
          {row.original.actions.status === 0 ? (
            <IconButton
              icon={<MdOutlineAdd />}
              onClick={() => handleIconClick('activateObject', row.original.id.toString())}
            />
          ) : (
            <IconButton
              icon={
                <MdRemove
                  onClick={() => handleIconClick('deactivateObject', row.original.id.toString())}
                />
              }
            />
          )}
          <IconButton
            icon={<MdDelete />}
            onClick={() => handleIconClick('removeObject', row.original.id.toString())}
          />
        </Buttons>
      ),
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ] as any[];

  const getOpenModal = Object.keys(modals).find(key => modals[key as keyof ModalState] === true);

  return (
    <ListWrapper>
      <ConfirmationModal
        size="small"
        isOpen={
          getOpenModal
            ? modalHandler(getOpenModal as keyof ModalState, objectIdToBeMutated).isOpen
            : false
        }
        title={t('confirmYourChoice')}
        message={t(modalHandler(getOpenModal as keyof ModalState, objectIdToBeMutated).message)}
        confirmText={t(
          modalHandler(getOpenModal as keyof ModalState, objectIdToBeMutated).confirmText,
        )}
        onConfirm={modalHandler(getOpenModal as keyof ModalState, objectIdToBeMutated).onConfirm}
        onClose={modalHandler(getOpenModal as keyof ModalState, objectIdToBeMutated).close}
      />
      {!integrationObjectsData && isFetching && (
        <WrapperCenter>
          <LoadingIndicator />
        </WrapperCenter>
      )}
      {!integrationObjectsData && !isFetching && (
        <>
          <WrapperCenter>{t('integrationObjectListIsEmpty')}</WrapperCenter>
          <WrapperCenter>
            <Button secondary size="sm" onClick={() => navigate(routes.samsungIntegrationForm)}>
              {t('addObject')}
            </Button>
          </WrapperCenter>
        </>
      )}

      {integrationObjectsData && (
        <TableWrapper>
          <Table
            styleLastRow
            data={integrationObjectsData}
            columns={columns}
            isLoading={isFetching}
          />
        </TableWrapper>
      )}
    </ListWrapper>
  );
};

export default SamsungIntegrationList;
