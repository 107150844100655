import styled, { keyframes } from 'styled-components';
import { useMainLayout, useWindowSize } from 'utils';

const animateModalOpen = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  60% {
    opacity: 0
  }

  100% {
    transform: translateY(0);
    opacity: 1
  }
`;

const Container = styled.div<{ isMobile: boolean; noPadding?: boolean; showMobileModal?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.3rem;
  padding: ${({ noPadding }) => (noPadding ? '0' : '1rem')};
  margin: 0 auto;
  width: 100%;
  background: ${p => p.theme.colors.primaryBackground};
  box-shadow: ${p => p.theme.shadows.xl};
  z-index: ${p => p.theme.layersOrder.topbar};
  position: fixed;
  bottom: 0;
  right: 0;
  border-radius: 1rem 1rem 0rem 0rem;
  animation: ${({ showMobileModal }) => showMobileModal && animateModalOpen} 0.3s ease;
`;

const HideButton = styled.button`
  width: 2rem;
  height: 0.25rem;
  border-radius: 6.25rem;
  opacity: 0.5;
  background: #79747e;
  margin: 1rem 0;
  cursor: pointer;
`;

const MobileModal = ({
  children,
  noPadding,
  displayModal,
  setDisplayModal,
}: {
  children: React.ReactNode;
  noPadding?: boolean;
  displayModal?: boolean;
  setDisplayModal?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { isMobile } = useWindowSize();
  const { showMobileModal, setShowMobileModal } = useMainLayout();

  /**
   * This function is used to close the mobile modal
   * based on the state passed to the component
   * or the state from the context
   */
  const handleCloseModal = () => {
    setDisplayModal ? setDisplayModal(false) : setShowMobileModal(false);
  };

  return (
    <Container
      noPadding={noPadding}
      isMobile={isMobile}
      showMobileModal={displayModal || showMobileModal}
    >
      <HideButton onClick={handleCloseModal} />
      {children}
    </Container>
  );
};

export default MobileModal;
