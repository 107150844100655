import LoadingIndicator from 'components/LoadingIndicator';
import { memo, useCallback, useMemo, useRef } from 'react';
import { Row, usePagination, useTable } from 'react-table';
import { useIntersectionObserver, useWindowSize } from 'utils';

import Pagination from './Pagination';
import {
  MobileContent,
  MobileRow,
  MobileTitle,
  MobileWrapper,
  TableWrapper,
  Tbody,
  Td,
  Th,
  Tr,
  Wrapper,
} from './style';

type PaginationProps = {
  totalItems: number;
  itemsPerPage: number;
};

type TableProps = {
  rowProps?: (row: Row<{}>) => {
    [key: string]: boolean;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: readonly any[];
  data: readonly {}[];
  styleLastRow?: boolean;
  pagination?: PaginationProps;
  onPageChange?: (page?: number) => void;
  isLoading?: boolean;
  canFetchMore?: boolean;
};

const Table = ({
  rowProps,
  columns,
  data,
  styleLastRow,
  pagination,
  onPageChange,
  isLoading = false,
  canFetchMore = true,
}: TableProps) => {
  const { isMobile } = useWindowSize();
  const lastItemRef = useRef<HTMLDivElement>(null);

  const totalPages = useMemo(() => {
    if (!pagination) return 0;

    return Math.ceil(pagination.totalItems / pagination.itemsPerPage);
  }, [pagination]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    pageCount,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
      initialState: { pageIndex: 0, pageSize: pagination?.itemsPerPage },
    },
    usePagination,
  );

  const fetchMore = useCallback(() => {
    onPageChange?.();
  }, [pageIndex, onPageChange]);

  useIntersectionObserver({
    target: lastItemRef,
    onIntersect: fetchMore,
    enabled: isMobile && !isLoading && canFetchMore,
  });

  if (isMobile) {
    return (
      <>
        {page.map((row, index) => {
          prepareRow(row);
          return (
            <MobileWrapper {...row.getRowProps(rowProps && rowProps(row))} key={`mw-${index}`}>
              {row.cells.map((cell, index) => (
                <MobileRow
                  styleLastRow={styleLastRow}
                  {...cell.getCellProps()}
                  key={`rm-${cell.column.Header}${index}`}
                >
                  <MobileTitle>{cell.render('Header')}</MobileTitle>
                  <MobileContent>{cell.render('Cell')}</MobileContent>
                </MobileRow>
              ))}
            </MobileWrapper>
          );
        })}
        <div ref={lastItemRef} />
        {isLoading && <LoadingIndicator />}
      </>
    );
  }

  return (
    <TableWrapper>
      {isLoading && <LoadingIndicator centerVertically />}
      {!isLoading && !!page.length && (
        <Wrapper {...getTableProps()} marginBottom={!!pagination}>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={`hg-${index}`}>
                {headerGroup.headers.map((column, index) => (
                  <Th {...column.getHeaderProps()} key={`th-${index}`}>
                    {column.render('Header')}
                  </Th>
                ))}
              </tr>
            ))}
          </thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps(rowProps && rowProps(row))} key={`tr-${index}`}>
                  {row.cells.map((cell, index) => (
                    <Td {...cell.getCellProps()} key={`td-${index}`}>
                      {cell.render('Cell')}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Wrapper>
      )}
      {pagination && (
        <Pagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          onPageChange={onPageChange}
          goToPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          hideOnSinglePage
        />
      )}
    </TableWrapper>
  );
};

export default memo(Table);
