import Button from 'components/Button';
import Input from 'components/Input';
import { useFormik } from 'formik';
import { TFunction } from 'i18next';
import i18n from 'locales/i18n';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useWindowSize } from 'utils';
import * as Yup from 'yup';

const inputStyles = `
  height: 3rem;
  border: none;
  margin-left: 0.5rem;
  background: #fff;
  font-size: 14px;

  &:focus-visible {
    outline: none;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: white,0 0 0 50px white inset;
    -webkit-text-fill-color: white;
}

&:hover:not([disabled]),
&:focus,
&:focus-visible {
  border: none;
  outline: none;
}
`;

export const PhoneSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  align-items: start;
  justify-content: start;
`;

export const PhoneInputWrapper = styled.div<{ isEditable?: boolean }>`
  display: grid;
  grid-template-columns: ${({ isEditable }) => (isEditable ? '0.5rem 3.5rem 1fr' : '3.5rem 1fr')};
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  width: 100%;
  position: relative;
`;

export const PhoneLabel = styled.label`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

const PrefixInput = styled(Input)<{ withBorder?: boolean }>`
  ${inputStyles};
  color: ${({ theme }) => theme.colors.text};
  border-radius: 0;
  padding: 0;

  ${p =>
    p.withBorder &&
    p.theme &&
    css`
      border-right: 1px solid ${({ theme }) => theme.colors.gray400} !important;
    `}
`;

const PhoneInput = styled(Input)`
  ${inputStyles};
  color: ${({ theme }) => theme.colors.text};
  padding: 0 0 0 2rem;
`;

export const StyledButton = styled(Button)`
  align-self: end;
`;
const ErrorMessageWrapper = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.error};
  font-size: 1rem;
`;

export const BottomSection = styled.section<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: ${({ isMobile }) => (isMobile ? '12px 24px' : '2.4rem')};
  gap: ${({ isMobile }) => (isMobile ? '12px' : '1.5rem')};
  width: 100%;
`;

const SingleStar = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.colors.error};
  top: 2px;
  left: 7px;
`;

const DoubleStar = styled(SingleStar)`
  position: absolute;
  color: ${({ theme }) => theme.colors.error};
  left: 62px;
  top: 2px;
  z-index: 1000;
`;

export type PhoneFormData = {
  prefix: string;
  phone: string;
};

const initialValues: PhoneFormData = {
  prefix: '48',
  phone: '',
};

interface GenerateLinkFormProps {
  objectId: number;
  handleSubmit: (objectId: number, values: PhoneFormData) => void;
  isLoading: boolean;
}

const PhoneSchema = (t: TFunction) =>
  Yup.object().shape({
    phone: Yup.string()
      .min(9, t('minCharacters', { number: 9 }))
      .max(9, t('maxCharacters', { number: 9 }))
      .required(t('required'))
      .matches(/^\d+$/, t('onlyNumbers')),
    prefix: Yup.string()
      .min(2, t('minCharacters', { number: 2 }))
      .max(3, t('maxCharacters', { number: 3 }))
      .required(t('required'))
      .matches(/^\d+$/, t('onlyNumbers')),
  });

const GenerateLinkForm = ({ objectId, handleSubmit, isLoading }: GenerateLinkFormProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const [scheme, setScheme] = useState<Yup.AnyObjectSchema>(PhoneSchema(t));

  useEffect(() => {
    async function onLanguageChanged() {
      setScheme(PhoneSchema(t));
    }

    i18n.on('languageChanged', onLanguageChanged);

    return () => {
      i18n.off('languageChanged', onLanguageChanged);
    };
  }, [i18n, t]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: values => {
      handleSubmit(objectId, values);
    },
    validationSchema: scheme,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <BottomSection isMobile={isMobile}>
        <PhoneSection>
          <PhoneLabel>{t('phoneNumber')}</PhoneLabel>
          <PhoneInputWrapper isEditable>
            {formik.errors.prefix && <SingleStar>*</SingleStar>}
            {'+'}
            <PrefixInput
              name="prefix"
              type="tel"
              value={formik.values.prefix}
              onChange={formik.handleChange}
              withBorder
            />
            {formik.errors.phone && <DoubleStar>**</DoubleStar>}
            <PhoneInput
              name="phone"
              type="tel"
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
          </PhoneInputWrapper>
        </PhoneSection>
        {formik.errors.prefix && (
          <ErrorMessageWrapper>{`* ${formik.errors.prefix}`}</ErrorMessageWrapper>
        )}
        {formik.errors.phone && (
          <ErrorMessageWrapper>{`** ${formik.errors.phone}`}</ErrorMessageWrapper>
        )}
        <StyledButton type="submit" size="sm" secondary disabled={isLoading}>
          {t('generateLink')}
        </StyledButton>
      </BottomSection>
    </form>
  );
};

export default GenerateLinkForm;
