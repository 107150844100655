import Arrow from 'assets/arrow-right.svg';
import LoadingIndicator from 'components/LoadingIndicator';
import { Icon } from 'components/Select';
import { getStatusList } from 'modules/driver/queries';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useAuth } from 'utils';
import { useAppContext } from 'utils/appContext';

const Wrapper = styled.div<{ dark?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ dark, theme }) => (dark ? 'none' : theme.colors.secondaryBackground)};
  border: ${({ dark, theme }) =>
    dark ? `1px solid ${theme.colors.gray300}` : `1px solid ${theme.colors.gray200}`};
  border-radius: 0.6rem;
  padding-left: 0.5rem;
  height: 42px;
  gap: 1rem;
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.4rem;
  font-weight: 600;
  transition: 0.2s ease;
  cursor: pointer;

  &:hover {
    background: ${({ dark, theme }) => (dark ? theme.colors.gray500 : theme.colors.gray200)};
    border: ${({ dark, theme }) =>
      dark ? `1px solid ${theme.colors.gray400}` : `1px solid ${theme.colors.gray300}`};
  }
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  height: 27px;
  gap: 1rem;
`;

const FirstStatus = styled.div<{ color: string; dark?: boolean }>`
  padding: 4px 8px;
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  align-items: center;
  border-left: 4px solid ${({ color }) => color};
  border-radius: 0.6rem;
  background: ${({ dark, theme }) => (dark ? 'none' : theme.colors.white)};
  border-top: ${({ dark, theme }) => (dark ? `1px solid ${theme.colors.gray300}` : `none`)};
  border-right: ${({ dark, theme }) => (dark ? `1px solid ${theme.colors.gray300}` : `none`)};
  border-bottom: ${({ dark, theme }) => (dark ? `1px solid ${theme.colors.gray300}` : `none`)};
`;

const OtherStatuses = styled.div<{ dark?: boolean }>`
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  background: ${({ dark, theme }) => (dark ? 'none' : theme.colors.white)};
  border-radius: 0.6rem;
  border: ${({ dark, theme }) => (dark ? `1px solid ${theme.colors.gray300}` : `none`)};
`;

const StatusSection = ({ onClickHandler }: { onClickHandler: () => void }) => {
  const { t } = useTranslation();
  const { darkMode } = useAppContext();
  const { isAuthenticated } = useAuth();

  const {
    data: status,
    isFetching: isStatusFetching,
    refetch: refetchStatuses,
  } = useQuery(getStatusList({ enabled: isAuthenticated }));
  const firstStatus = status?.data?.find(status => status.state > 0);
  const otherStatuses = status?.data?.filter(status => status.state > 0);

  useEffect(() => {
    if (isAuthenticated) refetchStatuses();
  }, [isAuthenticated]);

  return (
    <Wrapper onClick={onClickHandler} dark={darkMode}>
      {isStatusFetching ? (
        <LoadingIndicator small />
      ) : (
        <StatusWrapper>
          <FirstStatus color={firstStatus?.color ?? ''} dark={darkMode}>
            {t(firstStatus?.label ?? '')}
          </FirstStatus>
          {otherStatuses && otherStatuses.length > 1 && (
            <OtherStatuses dark={darkMode}>{`+${otherStatuses.length - 1}`}</OtherStatuses>
          )}
        </StatusWrapper>
      )}

      <Icon>
        <SVG src={Arrow} width={24} />
      </Icon>
    </Wrapper>
  );
};

export default StatusSection;
