import { SelectItem } from 'components/common';
import Input from 'components/Input';
import LoadingIndicator from 'components/LoadingIndicator';
import Select from 'components/Select';
import { useSelect } from 'downshift';
import { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  IconWrapper,
  List,
  ListItem,
  ListWrapper,
  SearchSingleInputWrapper,
  Separator,
  Wrapper,
} from './style';

export type SelectDropdownProps = {
  placeholderText?: string;
  items: SelectItem[];
  onChange?: (e: string) => void;
  value?: string;
  icon?: React.ReactElement;
  noBackground?: boolean;
  size?: string;
  isLoading?: boolean;
  blockSelectedOption?: boolean;
  isDropdown?: boolean;
  fillWidth?: boolean;
  minWidth?: number;
  disableInitialWidth?: boolean;
  defaultItem?: SelectItem;
  withSearch?: boolean;
  maxHeight?: number;
};

const SelectDropdown = ({
  placeholderText,
  items,
  onChange,
  value,
  icon,
  noBackground,
  size,
  isLoading,
  blockSelectedOption,
  isDropdown,
  fillWidth,
  disableInitialWidth,
  minWidth,
  defaultItem,
  withSearch,
  maxHeight,
}: SelectDropdownProps) => {
  const [searchValue, setSearchValue] = useState<string>('');

  /**
   * Handles search input change
   */
  const handleSearch = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  /**
   * Filter items based on search value
   */
  const filteredItems = useMemo(() => {
    if (!searchValue) return items as SelectItem[];

    const filtered = items.filter(item =>
      item.label.toLowerCase().includes(searchValue.toLowerCase()),
    );

    return filtered as SelectItem[];
  }, [searchValue, items]);

  const {
    isOpen,
    selectedItem,
    reset,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect<SelectItem>({
    items: filteredItems,
    defaultSelectedItem: defaultItem,
  });

  useEffect(() => {
    if (!selectedItem) return;
    onChange?.(selectedItem.value);
    isDropdown && reset();
  }, [selectedItem]);

  useEffect(() => {
    if (!value) reset();
  }, [value]);

  const { t } = useTranslation();

  const selectedOptions = useMemo(() => {
    if (blockSelectedOption) return [];

    return selectedItem ? [selectedItem] : value ? [items.find(v => v.value === value)] : [];
  }, [blockSelectedOption, selectedItem, items, value]);

  const placeholder = blockSelectedOption
    ? placeholderText
    : selectedItem
    ? selectedItem?.value
    : placeholderText;

  const selectIcon = useMemo(() => {
    const defaultItem = items.find(i => i.value === value);

    const item = selectedItem || defaultItem;

    return typeof item?.icon === 'string' ? item.icon : icon;
  }, [selectedItem, value]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnItemClick = useCallback((e: any, item: SelectItem, itemProps: any) => {
    if (item.disabled) return;
    setSearchValue('');
    return itemProps.onClick(e);
  }, []);

  const selectRef = useRef<HTMLDivElement>(null);

  const showDropdownOnTop = useMemo(() => {
    const { bottom = 0 } = selectRef.current?.getBoundingClientRect() ?? {};

    return window.innerHeight - bottom <= 300;
  }, [selectRef.current]);

  return (
    <Wrapper>
      {isLoading && <LoadingIndicator small />}
      {!isLoading && (
        <>
          <Select
            noBackground={noBackground}
            size={size}
            fillWidth={fillWidth}
            placeholder={placeholder}
            {...getToggleButtonProps({}, { suppressRefError: true })}
            selectedOptions={selectedOptions}
            icon={selectIcon}
            disableInitialWidth={disableInitialWidth}
            ref={selectRef}
          />
          <div {...getMenuProps({}, { suppressRefError: true })}>
            <ListWrapper
              showOnTop={showDropdownOnTop}
              minWidth={minWidth}
              maxHeight={maxHeight ? maxHeight + (withSearch ? 90 : 50) : 300}
              isOpen={isOpen}
            >
              {/* render search input */}
              {isOpen && withSearch && (
                <SearchSingleInputWrapper>
                  <Input
                    inputSize={'md'}
                    onChange={e => handleSearch(e.target.value)}
                    placeholder={t('search')}
                    type="search"
                    value={searchValue}
                  />
                </SearchSingleInputWrapper>
              )}

              {/* render list */}
              <List maxHeight={maxHeight ?? 250}>
                {!isOpen
                  ? null
                  : filteredItems.map((item, index) => {
                      const itemProps = getItemProps({ item, index });

                      return (
                        <Fragment key={`${item.value}${index}`}>
                          <ListItem
                            highlighted={highlightedIndex === index}
                            active={selectedItem?.value === item.value}
                            {...itemProps}
                            disabled={item.disabled}
                            onClick={e => handleOnItemClick(e, item, itemProps)}
                          >
                            {typeof item.icon === 'string' ? (
                              <IconWrapper src={item.icon} />
                            ) : (
                              item.icon
                            )}
                            {item.label}
                          </ListItem>
                          {item.addSeparator && <Separator />}
                        </Fragment>
                      );
                    })}
              </List>
            </ListWrapper>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default memo(SelectDropdown);
