import {
  DOCUMENTS_ALL,
  DRIVERS,
  DRIVERS_ALL,
  HttpError,
  LAST_DRIVERS_STATUSES,
  LOGIN,
  OBJECTS,
  OBJECTS_ALL,
  STATUSES,
  STATUSES_ALL,
  UNREAD_STATISTICS,
} from 'common';
import { Status } from 'components/common';
import queryClient from 'lib/react-query';
import { useTranslation } from 'react-i18next';
import { UseMutationOptions, UseQueryOptions } from 'react-query';
import { useToast } from 'toast';
import { useMainLayout } from 'utils';

import { requests } from '../api';
import {
  AuthDriverDto,
  CreateDriverAccountDto,
  DocumentContentDto,
  DocumentsListResponse,
  DriversAccountsResponseDto,
  LastDriversStatusResponse,
  StatusParamsDto,
  StatusResponseDto,
  UnreadStatisticsResponseDto,
  UpdateStatusDto,
} from '../dtos';
import { DeleteLinkDto } from './../dtos/index';

/* eslint-disable @typescript-eslint/no-explicit-any */

export const authDriver = (
  config: UseMutationOptions<unknown, HttpError<unknown>, AuthDriverDto> = {},
) => ({
  mutationFn: requests.authDriver,
  onSuccess: () => queryClient.invalidateQueries(LOGIN),
  ...config,
});

export const getStatusList = (
  config: UseQueryOptions<StatusResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [STATUSES, STATUSES_ALL],
  queryFn: () => requests.getStatusList(),
  ...config,
});

export const updateStatus = (
  config: UseMutationOptions<unknown, HttpError<unknown>, UpdateStatusDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();
  const { setShowMobileModal } = useMainLayout();

  return {
    mutationFn: requests.updateStatus,
    ...config,
    onSuccess: (response: any) => {
      queryClient.invalidateQueries([STATUSES, STATUSES_ALL]);
      setShowMobileModal(false);
      showToast(
        response.status
          ? { content: t('updateStatusFailure'), type: Status.Negative }
          : { content: t('updateStatusSuccess') },
      );
    },
    onError: () => showToast({ content: t('updateStatusFailure'), type: Status.Negative }),
  };
};

export const getDriversAccounts = (
  params?: StatusParamsDto,
  config: UseQueryOptions<DriversAccountsResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: params ? [DRIVERS, params] : [DRIVERS, DRIVERS_ALL],
  queryFn: () => requests.getDriversAccounts(params),
  ...config,
});

export const createDriverAccount = (
  config: UseMutationOptions<unknown, HttpError<unknown>, CreateDriverAccountDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.createDriverAccount,
    ...config,
    onSuccess: () => {
      queryClient.invalidateQueries([DRIVERS]);
      queryClient.invalidateQueries([OBJECTS, OBJECTS_ALL]);
      showToast({ content: t('linkGenerationSuccess') });
    },
    onError: () => showToast({ content: t('linkGenerationFailure'), type: Status.Negative }),
  };
};

export const deleteDriversLink = (
  config: UseMutationOptions<unknown, HttpError<unknown>, DeleteLinkDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.deleteDriversLink,
    ...config,
    onSuccess: () => {
      queryClient.invalidateQueries([DRIVERS]);
      queryClient.invalidateQueries([OBJECTS, OBJECTS_ALL]);
      showToast({ content: t('deleteLinkSuccess') });
    },
    onError: () => showToast({ content: t('deleteLinkFailure'), type: Status.Negative }),
  };
};

export const getUnreadStatistics = (
  config: UseQueryOptions<UnreadStatisticsResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [UNREAD_STATISTICS],
  queryFn: () => requests.getUnreadStatistics(),
  ...config,
});

export const getLastDriversStatus = (
  config: UseQueryOptions<LastDriversStatusResponse, HttpError<unknown>> = {},
) => ({
  queryKey: [LAST_DRIVERS_STATUSES],
  queryFn: () => requests.getLastDriversStatus(),
  ...config,
});

export const getDocumentsList = (
  config: UseQueryOptions<DocumentsListResponse, HttpError<unknown>> = {},
) => ({
  queryKey: [DOCUMENTS_ALL],
  queryFn: () => requests.getDocumentsList(),
  ...config,
});

export const addDocument = (
  config: UseMutationOptions<unknown, HttpError<unknown>, DocumentContentDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.addDocument,
    ...config,
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENTS_ALL]);
    },
    onError: () => showToast({ content: t('addDocumentFailure'), type: Status.Negative }),
  };
};

export const deleteDocument = (
  config: UseMutationOptions<unknown, HttpError<unknown>, number> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.deleteDocument,
    ...config,
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENTS_ALL]);
      showToast({ content: t('deletePhotoSuccess') });
    },
    onError: () => showToast({ content: t('deletePhotoFailure'), type: Status.Negative }),
  };
};
