import { TFunction } from 'i18next';

interface Map {
  [key: string]: string;
}

export const vehiclesTranslations = (t: TFunction<'translation'>): Map => ({
  excavator: t('excavator'),
  truck: t('truck'),
  car: t('car'),
  'truck-supply': t('truckSupply'),
  locomotive: t('locomotive'),
  compactor: t('compactor'),
  combine: t('combine'),
  boat: t('boat'),
  'jet-ski': t('jetSki'),
  animals: t('animals'),
  tractor: t('tractor'),
  mower: t('mower'),
  motorcycle: t('motorcycle'),
  generator: t('generator'),
  semitrailer: t('semitrailer'),
});
