import { AllowedModules } from 'common';
import {
  AppModules,
  AppModulesAdmin,
  AppModulesIntegrations,
  AppModulesPremium,
  getMenuItemData,
  getSubMenuItemData,
} from 'common/menuItems';
import NotFound from 'components/404/NotFound';
import LoginsAndGroups from 'modules/admin/pages/LoginsAndGroups';
import { AppModuleDto } from 'modules/auth/dtos/UpdateMenuResponse.dto';
import DriverCamera from 'modules/driver/pages/DriverCamera';
import DriverGallery from 'modules/driver/pages/DriverGallery';
import DriverMap from 'modules/driver/pages/DriverMap';
import DriverMessenger from 'modules/driver/pages/DriverMessenger';
import HashError from 'modules/driver/pages/HashError';
import Spedition from 'modules/driver/pages/Spedition';
import SpeditionChat from 'modules/driver/pages/SpeditionChat';
import SamsungIntegration from 'modules/integrations/pages/SamsungIntegration';
import SamsungIntegrationForm from 'modules/integrations/pages/SamsungIntegrationForm';
import SamsungIntegrationList from 'modules/integrations/pages/SamsungIntegrationList/index';
import RoadCardPreview from 'modules/objects/pages/RoadCardPreview';
import { CustomsServiceListTypes } from 'modules/premium/common/CustomsServiceListTypes';
import { ReportTypes } from 'modules/reports/common';
import Sharing from 'modules/sharing/pages/Sharing';
import { lazy, memo } from 'react';
import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom';
import { useAuth, useCheckModuleAccess, useUserData, useWindowSize } from 'utils';
import useAppModule from 'utils/hooks/useAppModule';

import RouteContextProvider from '../modules/route/contexts/RouteContextProvider';
import ProtectedRoute from './ProtectedRoute';
import { routes } from './routes';

const Access = lazy(() => import('modules/auth/pages/Access'));
const LoginPage = lazy(() => import('modules/auth/pages/Login'));
const ForgotPassword = lazy(() => import('modules/auth/pages/ForgotPassword'));
const ResetPassword = lazy(() => import('modules/auth/pages/ResetPassword'));
const Objects = lazy(() => import('modules/objects/pages/Objects'));
const ControllsHistory = lazy(() => import('modules/objects/pages/ControllsHistory'));
const Settings = lazy(() => import('modules/settings/pages/Settings'));
const ContactDetails = lazy(() => import('modules/settings/pages/ContactDetails'));
const ChangePassword = lazy(() => import('modules/settings/pages/ChangePassword'));
const ColorMode = lazy(() => import('modules/settings/pages/ColorMode'));
const SpeedLimit = lazy(() => import('modules/settings/pages/SpeedLimit'));
const EngineStart = lazy(() => import('modules/settings/pages/EngineStart'));
const VehicleUnplug = lazy(() => import('modules/settings/pages/VehicleUnplug'));
const Service = lazy(() => import('modules/settings/pages/Service'));
const BatteryVoltage = lazy(() => import('modules/settings/pages/BatteryVoltage'));
const Rpm = lazy(() => import('modules/settings/pages/Rpm'));
const LogBook = lazy(() => import('modules/settings/pages/LogBook'));
const VatUs = lazy(() => import('modules/settings/pages/VatUs'));
const WorkingDistance = lazy(() => import('modules/settings/pages/WorkingDistance'));
const DistanceSource = lazy(() => import('modules/settings/pages/DistanceSource'));
const RouteSettings = lazy(() => import('modules/settings/pages/Route'));
const OthersObjects = lazy(() => import('modules/settings/pages/others-objects/OthersObjects'));
const OthersMenuBar = lazy(() => import('modules/settings/pages/others-menu-bar/OthersMenuBar'));
const MapSettings = lazy(() => import('modules/settings/pages/Map'));
const ObjectsSettings = lazy(() => import('modules/settings/pages/Objects'));
const RoutePage = lazy(() => import('modules/route/pages/Route'));
const Reports = lazy(() => import('modules/reports/pages/Reports'));
const ReportsForm = lazy(() => import('modules/reports/pages/Form'));
const ReportOthersForm = lazy(() => import('modules/reports/pages/OthersForm'));
const ReportsList = lazy(() => import('modules/reports/pages/ReportsList'));
const LogbookForm = lazy(() => import('modules/reports/pages/LogbookForm'));
const MilogForm = lazy(() => import('modules/reports/pages/MilogForm'));
const BordercrossForm = lazy(() => import('modules/reports/pages/BordercrossForm'));
const DistanceCombustionForm = lazy(() => import('modules/reports/pages/DistanceCombustionForm'));
const RefuelingForm = lazy(() => import('modules/reports/pages/RefuelingForm'));
const AutomationList = lazy(() => import('modules/reports/pages/AutomationList'));
const VatUsForm = lazy(() => import('modules/reports/pages/VatUsForm'));
const CustomsServiceList = lazy(() => import('modules/premium/pages/CustomsServiceList'));
const CustomsServiceHistory = lazy(() => import('modules/premium/pages/CustomsServiceHistory'));
const ChangeLog = lazy(() => import('modules/settings/pages/changelog/ChangeLog'));
const SharingPreview = lazy(() => import('modules/sharing/pages/SharingPreview'));
const ReportPreview = lazy(() => import('modules/reports/pages/ReportPreview'));
const ReportPreviewPdf = lazy(() => import('modules/reports/pages/ReportPreviewPdf'));
const DriverMenu = lazy(() => import('modules/driver/pages/DriverMenu'));

export type LocationState = {
  previous: string;
};

/**
 * This function is used to get initial route based on available app modules
 * by default it will return first available module
 * @param isDriverAccount
 * @param appModules
 * @returns
 */
const getInitialRoute = (isDriverAccount: boolean, appModules: AppModuleDto[]) => {
  // redirect driver to different system part
  if (isDriverAccount) return routes.driverMap;

  // redirect to login if user has no modules
  if (!appModules) return routes.login;
  if (appModules?.length === 0) return routes.login;

  // get first module
  const firstModule = appModules?.[0];
  const firstModuleData = getMenuItemData(firstModule?.id ?? '');

  // if first module is select type - get first available sub module
  if (!firstModuleData?.route) {
    const firstSubModule = firstModule?.subModules?.[0];
    const firstSubModuleData = getSubMenuItemData(firstModule?.id ?? '', firstSubModule?.id ?? '');
    return firstSubModuleData?.route ?? routes.login;
  }

  return firstModuleData.route ?? routes.login;
};

const Router = () => {
  const { isAuthenticated } = useAuth();
  const { isMobile } = useWindowSize();
  const checkModuleAccess = useCheckModuleAccess();
  const checkAppModuleAccess = useAppModule();
  const userData = useUserData();
  const isDriverAccount = checkModuleAccess(AllowedModules.DRIVER_LIMITED);

  return (
    <RouterRoutes>
      <Route
        path="/"
        element={
          <Navigate to={getInitialRoute(isDriverAccount, userData.appModules)} replace={true} />
        }
      />
      <Route
        path={routes.access}
        element={
          <ProtectedRoute condition={!isAuthenticated} redirectTo={'/'}>
            <Access />
          </ProtectedRoute>
        }
      >
        <Route path={routes.access} element={<Navigate to={routes.login} replace />} />
        <Route path={routes.login} element={<LoginPage />} />
        <Route path={routes.forgotPassword} element={<ForgotPassword />} />
        <Route path={routes.resetPassword(':token')} element={<ResetPassword />} />
      </Route>
      <Route
        path={routes.objects}
        element={
          <ProtectedRoute
            condition={checkAppModuleAccess({ module: AppModules.OBJECTS })}
            redirectTo={isDriverAccount ? '/*' : routes.login}
          >
            <Objects />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.controllsHistory(':objectId' as unknown as number)}
        element={
          <ProtectedRoute
            condition={checkModuleAccess(AllowedModules.VEHICLE_COMMAND_IGNITION)}
            redirectTo={isDriverAccount ? '/*' : routes.login}
          >
            <ControllsHistory />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.roadCardPreview(':objectId' as unknown as number)}
        element={
          <ProtectedRoute condition={isAuthenticated} redirectTo={routes.login}>
            <RoadCardPreview />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.settings}
        element={
          <ProtectedRoute
            condition={isAuthenticated && !isDriverAccount}
            redirectTo={isDriverAccount ? '/*' : routes.login}
          >
            <Settings />
          </ProtectedRoute>
        }
      >
        <Route path={routes.contactDetailsSettings} element={<ContactDetails />} />
        <Route path={routes.changePasswordSettings} element={<ChangePassword />} />
        <Route path={routes.colorModeSettings} element={<ColorMode />} />
        <Route
          path={routes.speedLimitSettings}
          element={
            <ProtectedRoute
              condition={checkModuleAccess(AllowedModules.SETTINGS_NOTIFICATIONS_SPEED_LIMIT)}
              redirectTo="*"
            >
              <SpeedLimit />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.engineStartSettings}
          element={
            <ProtectedRoute
              condition={checkModuleAccess(AllowedModules.SETTINGS_NOTIFICATIONS_ENGINE_START)}
              redirectTo="*"
            >
              <EngineStart />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.vehicleUnplugSettings}
          element={
            <ProtectedRoute
              condition={checkModuleAccess(AllowedModules.SETTINGS_NOTIFICATIONS_VEHICLE_UNPLUG)}
              redirectTo="*"
            >
              <VehicleUnplug />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.serviceSettings}
          element={
            <ProtectedRoute
              condition={checkModuleAccess(AllowedModules.SETTINGS_NOTIFICATIONS_SERVICE)}
              redirectTo="*"
            >
              <Service />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.batteryVoltageSettings}
          element={
            <ProtectedRoute
              condition={checkModuleAccess(AllowedModules.SETTINGS_NOTIFICATIONS_BATTERY_VOLTAGE)}
              redirectTo="*"
            >
              <BatteryVoltage />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.rpmSettings}
          element={
            <ProtectedRoute
              condition={checkModuleAccess(AllowedModules.SETTINGS_NOTIFICATIONS_RPM)}
              redirectTo="*"
            >
              <Rpm />
            </ProtectedRoute>
          }
        />
        <Route path={routes.logBookSettings} element={<LogBook />} />
        <Route path={routes.vatUsSettings} element={<VatUs />} />
        <Route path={routes.workingDistanceSettings} element={<WorkingDistance />} />
        <Route path={routes.distanceSourceSettings} element={<DistanceSource />} />
        <Route path={routes.routeSettings} element={<RouteSettings />} />
        <Route path={routes.otherObjectsSettings} element={<OthersObjects />} />
        <Route path={routes.otherMenuBar} element={<OthersMenuBar />} />
        <Route path={routes.mapSettings} element={<MapSettings />} />
        <Route
          path={routes.objectsSettings}
          element={
            <ProtectedRoute condition={isMobile} redirectTo={routes.settings}>
              <ObjectsSettings />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path={routes.route}
        element={
          <ProtectedRoute
            condition={checkAppModuleAccess({ module: AppModules.ROUTES })}
            redirectTo={isDriverAccount ? '/*' : routes.login}
          >
            <RouteContextProvider>
              <RoutePage />
            </RouteContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.reports}
        element={
          <ProtectedRoute
            condition={checkAppModuleAccess({ module: AppModules.REPORTS })}
            redirectTo={isDriverAccount ? '/*' : routes.login}
          >
            <Reports />
          </ProtectedRoute>
        }
      >
        <Route path={routes.reportsForm} element={<ReportsForm />} />
        <Route
          path={routes.reportLogbookForm}
          element={
            <ProtectedRoute
              condition={checkModuleAccess(AllowedModules.REPORTS_LOGBOOK)}
              redirectTo="*"
            >
              <LogbookForm />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.reportMilogForm}
          element={
            <ProtectedRoute
              condition={checkModuleAccess(AllowedModules.REPORTS_MILOG)}
              redirectTo="*"
            >
              <MilogForm />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.reportBordercrossForm}
          element={
            <ProtectedRoute
              condition={checkModuleAccess(AllowedModules.REPORTS_MILOG_CSV_CROSS)}
              redirectTo="*"
            >
              <BordercrossForm />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.reportDistanceCombustionForm}
          element={
            <ProtectedRoute
              condition={checkModuleAccess(AllowedModules.REPORTS_DISTANCE_COMBUSTION)}
              redirectTo="*"
            >
              <DistanceCombustionForm />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.reportRefuelingForm}
          element={
            <ProtectedRoute
              condition={checkModuleAccess(AllowedModules.REPORTS_FUEL_TAKS)}
              redirectTo="*"
            >
              <RefuelingForm />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.reportVatUsForm}
          element={
            <ProtectedRoute
              condition={checkModuleAccess(AllowedModules.REPORTS_VAT_US)}
              redirectTo="*"
            >
              <VatUsForm />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.reportOthersForm(':type' as ReportTypes)}
          element={<ReportOthersForm />}
        />
        <Route path={routes.reportsAutomation} element={<AutomationList />} />
        <Route path={routes.reportsList} element={<ReportsList />} />
      </Route>
      <Route
        path={routes.reportsPreview(':id', ':type' as ReportTypes)}
        element={
          <ProtectedRoute
            condition={checkModuleAccess(AllowedModules.REPORTS_GRAPHICAL_PREVIEW)}
            redirectTo={routes.login}
          >
            <ReportPreview />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.reportsPreviewPdf(':id', ':type' as ReportTypes)}
        element={
          <ProtectedRoute
            condition={checkModuleAccess(AllowedModules.REPORTS_GRAPHICAL_PREVIEW)}
            redirectTo={routes.login}
          >
            <ReportPreviewPdf />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.samsungIntegration}
        element={
          <ProtectedRoute
            condition={checkAppModuleAccess({
              module: AppModules.INTEGRATIONS,
              subModule: AppModulesIntegrations.SAMSUNG,
            })}
            redirectTo={'*'}
          >
            <SamsungIntegration />
          </ProtectedRoute>
        }
      >
        <Route path={routes.samsungIntegrationForm} element={<SamsungIntegrationForm />} />
        <Route path={routes.samsungIntegrationList} element={<SamsungIntegrationList />} />
      </Route>
      <Route path={routes.driverAuth} element={<DriverMenu />} />
      <Route path={routes.driverMap} element={<DriverMap />} />
      <Route path={routes.driverMessenger} element={<DriverMessenger />} />
      <Route path={routes.driverCamera} element={<DriverCamera />} />
      <Route path={routes.driverGallery} element={<DriverGallery />} />
      <Route path={routes.sharePreviewLink(':hash')} element={<SharingPreview />} />
      <Route path={routes.hashError} element={<HashError />} />
      <Route
        path={routes.spedition}
        element={
          <ProtectedRoute
            condition={checkAppModuleAccess({ module: AppModules.SPEDITION })}
            redirectTo={routes.login}
          >
            <Spedition />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.loginsAndGroups}
        element={
          <ProtectedRoute
            condition={checkAppModuleAccess({
              module: AppModules.ADMIN,
              subModule: AppModulesAdmin.LOGINS_AND_GROUPS,
            })}
            redirectTo={routes.login}
          >
            <LoginsAndGroups />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.sharing}
        element={
          <ProtectedRoute
            condition={checkAppModuleAccess({ module: AppModules.SHARING })}
            redirectTo={routes.login}
          >
            <Sharing />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.chat}
        element={
          <ProtectedRoute
            condition={checkAppModuleAccess({ module: AppModules.CHAT })}
            redirectTo={routes.login}
          >
            <SpeditionChat />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.changelog}
        element={
          <ProtectedRoute
            condition={checkAppModuleAccess({ module: AppModules.OBJECTS })}
            redirectTo={routes.login}
          >
            <ChangeLog />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.premiumSentGeo}
        element={
          <ProtectedRoute
            condition={checkAppModuleAccess({
              module: AppModules.PREMIUM,
              subModule: AppModulesPremium.SENT,
            })}
            redirectTo={routes.login}
          >
            <CustomsServiceList
              type={CustomsServiceListTypes.SENT_GEO}
              key={CustomsServiceListTypes.SENT_GEO}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.premiumEtoll}
        element={
          <ProtectedRoute
            condition={checkAppModuleAccess({
              module: AppModules.PREMIUM,
              subModule: AppModulesPremium.ETOLL,
            })}
            redirectTo={routes.login}
          >
            <CustomsServiceList
              type={CustomsServiceListTypes.ETOLL}
              key={CustomsServiceListTypes.ETOLL}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.premiumPuescHistory(':objectId', ':type' as CustomsServiceListTypes)}
        element={
          <ProtectedRoute
            condition={checkAppModuleAccess({
              module: AppModules.PREMIUM,
            })}
            redirectTo={routes.login}
          >
            <CustomsServiceHistory />
          </ProtectedRoute>
        }
      />
      <Route
        path="*"
        element={
          <ProtectedRoute condition={isAuthenticated} redirectTo={routes.login}>
            <NotFound />
          </ProtectedRoute>
        }
      />
    </RouterRoutes>
  );
};

export default memo(Router);
