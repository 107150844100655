import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg';
import LoadingIndicator from 'components/LoadingIndicator';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { routes } from 'navigation/routes';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getObjectIcon } from 'utils';

import { ObjectsParamsDto } from '../dtos';
import { RoadCardPreviewData } from '../dtos/GetRoadCard.dto';
import { getObjects, getRoadCard } from '../queries';

const RoadCardPreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
`;

const RoadCardTopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.regular};
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
`;

const CommentSection = styled.div`
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.colors.onPrimaryBorder};
  display: flex;
`;

const DataSection = styled.div`
  padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.xLarge}`};
`;

const StyledDataSection = styled(DataSection)`
  border-right: 1px solid ${({ theme }) => theme.colors.onPrimaryBorder};
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const RoadCardSummary = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.onPrimaryBorder};
  color: ${({ theme }) => theme.colors.text};
`;

const SummaryUnit = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xSmall};
  padding: ${({ theme }) => theme.spacing.regular};
`;

const SummaryUnitContent = styled.div`
  font-size: 24px;
  font-weight: 600;
  display: flex;
  gap: ${({ theme }) => theme.spacing.small};
`;

const TimeContentWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.small};
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-left: ${({ theme }) => `1px solid ${theme.colors.onPrimaryBorder}`};
  border-right: ${({ theme }) => `1px solid ${theme.colors.onPrimaryBorder}`};
`;

const TimeContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.small};
`;

const StyledTimeContent = styled(TimeContent)`
  border-right: 1px solid ${({ theme }) => theme.colors.onPrimaryBorder};
`;

const RoadCardTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 4fr 1fr 1fr 1fr;
  font-size: 14px;
  font-weight: 600;
`;

const TableRowWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 4fr 1fr 1fr 1fr;
`;

const TableCell = styled.div`
  padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.large}`};
  font-size: 14px;
  border-top: 1px solid ${({ theme }) => theme.colors.onPrimaryBorder};
`;

const BoldTableCell = styled(TableCell)`
  font-weight: 600;
  border-top: none;
`;

const TableSummary = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.onPrimaryBorder};
  display: grid;
  grid-template-columns: 7fr 1fr 1fr 1fr;
`;

const roadCardCols = (t: TFunction) => [
  t('date'),
  t('from'),
  t('to'),
  t('address'),
  t('work'),
  t('stop'),
  t('distanceInKm'),
];

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

const RoadCardPreview = () => {
  const { t } = useTranslation();
  const { objectId } = useParams<{ objectId: string }>();

  const { data: roadCardData, isLoading: isRoadCardDataLoading } = useQuery(
    getRoadCard(objectId ?? '', { enabled: !!objectId }),
  );

  const objectsParams = () => {
    if (!objectId) return undefined;

    return {
      ...(objectId && { 'object-filtering': [objectId] }),
    };
  };

  const { data: objectData, isLoading: isObjectDataLoading } = useQuery(
    getObjects(objectsParams() as ObjectsParamsDto),
  );

  const getSummary = (arg: keyof RoadCardPreviewData) => {
    if (isRoadCardDataLoading) return <LoadingIndicator small />;

    if (!roadCardData?.data.length) return;
    return roadCardData?.data
      .map(el => el[arg].toString().split(':'))
      .map(arr =>
        dayjs.duration({
          hours: parseFloat(arr[0]),
          minutes: parseFloat(arr[1] || '0'),
          seconds: parseFloat(arr[2] || '0'),
        }),
      )
      .reduce((acc, curr) => acc.add(curr))
      .format('HH:mm:ss');
  };

  const getDistance = () => {
    if (isRoadCardDataLoading) return <LoadingIndicator small />;
    if (!roadCardData?.data.length) return;
    return parseFloat(
      roadCardData?.data
        .map(el => el.distance)
        .reduce((acc, curr) => acc + curr, 0)
        .toString(),
    ).toFixed(2);
  };

  const isRoadCardDataAvailable = !!roadCardData?.data.length;

  return (
    <RoadCardPreviewContainer>
      <RoadCardTopSection>
        <StyledLink to={routes.objects}>
          <TitleSection>
            <ArrowLeft />
            <Title>{t('roadCardReport')}</Title>
          </TitleSection>
        </StyledLink>
        <CommentSection>
          <StyledDataSection>
            <Label>{t('date')}</Label>
            {isRoadCardDataLoading ? (
              <LoadingIndicator small />
            ) : (
              <>
                {isRoadCardDataAvailable
                  ? roadCardData?.data[0]?.date
                  : dayjs().startOf('day').format('DD-MM-YYYY HH:mm:ss')}{' '}
                {roadCardData?.data[0]?.start} -{' '}
                {roadCardData?.data[roadCardData?.data.length - 1]?.date}{' '}
                {isRoadCardDataAvailable
                  ? roadCardData?.data[roadCardData?.data.length - 1]?.end
                  : dayjs().endOf('day').format('DD-MM-YYYY HH:mm:ss')}
              </>
            )}
          </StyledDataSection>
          <DataSection>
            <Label>{t('comment')}</Label>
            <div>
              {isObjectDataLoading ? <LoadingIndicator small /> : objectData?.data[0].comment}
            </div>
          </DataSection>
        </CommentSection>
      </RoadCardTopSection>
      <RoadCardSummary>
        <SummaryUnit>
          <Label>{t('summaryFor')}</Label>
          <SummaryUnitContent>
            {isObjectDataLoading ? (
              <LoadingIndicator small />
            ) : (
              <>
                <img src={getObjectIcon(objectData?.data[0].type)} />
                <div>{objectData?.data[0].number}</div>
              </>
            )}
          </SummaryUnitContent>
        </SummaryUnit>
        <TimeContentWrapper>
          <StyledTimeContent>
            <Label>{t('workTimeUnits')}</Label>
            <SummaryUnitContent>
              {isObjectDataLoading ? (
                <LoadingIndicator small />
              ) : isRoadCardDataAvailable ? (
                getSummary('work')
              ) : (
                '00:00:00'
              )}
            </SummaryUnitContent>
          </StyledTimeContent>
          <TimeContent>
            <Label>{t('stopTimeUnits')}</Label>
            <SummaryUnitContent>
              {isObjectDataLoading ? (
                <LoadingIndicator small />
              ) : isRoadCardDataAvailable ? (
                getSummary('rest')
              ) : (
                '00:00:00'
              )}
            </SummaryUnitContent>
          </TimeContent>
        </TimeContentWrapper>

        <SummaryUnit>
          <Label>{t('distance')} (km)</Label>
          <SummaryUnitContent>{isRoadCardDataAvailable ? getDistance() : '0'}</SummaryUnitContent>
        </SummaryUnit>
      </RoadCardSummary>
      <RoadCardTable>
        <TableColumnsWrapper>
          {roadCardCols(t).map((col, i) => (
            <BoldTableCell key={i}>{col}</BoldTableCell>
          ))}
        </TableColumnsWrapper>
        <TableRowWrapper>
          {roadCardData?.data.map((row, i) => (
            <TableRow key={i}>
              {Object.values(row).map((el, index) => (
                <TableCell key={index}>{el}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableRowWrapper>
        <TableSummary>
          <BoldTableCell>{t('sum')}</BoldTableCell>
          <BoldTableCell>{getSummary('work')}</BoldTableCell>
          <BoldTableCell>{getSummary('rest')}</BoldTableCell>
          <BoldTableCell>{getDistance()}</BoldTableCell>
        </TableSummary>
      </RoadCardTable>
    </RoadCardPreviewContainer>
  );
};

export default RoadCardPreview;
