import WWLogo from 'assets/ww-logo-small.svg';
import { driverMenuItems } from 'common/menuItems';
import Button from 'components/Button';
import LanguageSelect from 'components/LanguageSelect';
import { GroupElements } from 'components/MobileTopbar';
import AddAttachement from 'modules/driver/components/AddAttachement';
import StatusManagement from 'modules/driver/components/StatusManagement';
import { routes } from 'navigation/routes';
import { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';
import { useMainLayout } from 'utils';

import StatusSection from './StatusSection';

const statusContainer = document.getElementById('status-container');

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${p => p.theme.spacing.regular};
  gap: ${p => p.theme.spacing.regular};
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  border-bottom: 1px solid ${p => p.theme.colors.gray200};
  overflow-x: auto;
  overflow-y: hidden;
`;

const MenuItemsWrapper = styled.div`
  display: flex;
  gap: ${p => p.theme.spacing.regular};
  align-items: center;
  margin-left: 1rem;
`;

export const Img = styled.img`
  height: 40px;
  margin-right: 1rem;
  cursor: pointer;
`;

export const StyledButton = styled(Button)<{ isActive: boolean }>`
  border: ${({ isActive, theme }) =>
    isActive ? `1px solid ${theme.colors.primary600}` : `1px solid ${theme.colors.gray200}`};

  background-color: ${p =>
    p.isActive ? p.theme.colors.primary500 : p.theme.colors.secondaryBackground};

  color: ${p => (p.isActive ? p.theme.colors.white : p.theme.colors.text)};

  &:hover {
    border: 1px solid
      ${({ theme, isActive }) => (isActive ? theme.colors.primary500 : theme.colors.gray300)};
    background-color: ${p =>
      p.isActive ? p.theme.colors.primary500 : p.theme.colors.secondaryBackgroundDark};
  }
`;

const DriverTopbar = () => {
  const navigate = useNavigate();
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { showMobileModal, setShowMobileModal } = useMainLayout();

  const [isMoreSectionOpen, setIsMoreSectionOpen] = useState<boolean>(false);

  const handleMoreClick = () => {
    setShowMobileModal(!showMobileModal);
    setIsMoreSectionOpen(prev => !prev);
  };

  const handleMenuItemClick = useCallback(
    (route?: string) => {
      if (!route) return;

      if (route === routes.driverGallery) {
        handleMoreClick();
      } else navigate(route);
    },
    [navigate],
  );

  /**
   * This function is used to handle click on status
   * It opens the status management modal
   */
  const handleStatusClick = () => {
    setShowMobileModal(!showMobileModal);
  };

  return (
    <>
      <Wrapper>
        <Img src={WWLogo} onClick={() => navigate(routes.driverAuth)} />

        <MenuItemsWrapper>
          {Object.entries(driverMenuItems).map(
            ([name, { icon, isDropdown, hideArrow, route, disabled }]) => (
              <StyledButton
                key={name}
                icon={icon}
                secondary
                isIconOrange={false}
                isDropdown={isDropdown}
                hideArrow={hideArrow}
                backgroundColor={location.pathname === route ? colors.primaryBackground : undefined}
                isActive={location.pathname === route}
                onClick={() => handleMenuItemClick(route)}
                disabled={disabled}
              >
                {t(name)}
              </StyledButton>
            ),
          )}
          <StatusSection onClickHandler={handleStatusClick} />
        </MenuItemsWrapper>

        <GroupElements>
          {/* <NotificationsIcon disabled={temporarilyHidden} /> */}
          <LanguageSelect />
        </GroupElements>
      </Wrapper>

      {/* <--- render modal ---> */}
      {showMobileModal &&
        createPortal(
          isMoreSectionOpen ? <AddAttachement onClose={handleMoreClick} /> : <StatusManagement />,
          statusContainer!,
        )}
    </>
  );
};

export default DriverTopbar;
