import Button from 'components/Button';
import { Form, Formik } from 'formik';
import { TFunction } from 'i18next';
import i18n from 'locales/i18n';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useWindowSize } from 'utils';
import * as Yup from 'yup';

import { LoginItem } from '../dtos';
import { updateLogin } from '../queries';
import { ButtonWrapper, NewGroupWrapper, StyledInputFormField } from './GroupForm';

interface UpdateLoginFormValuesProps {
  user: string;
}

interface UpdateLoginProps {
  handleSelectedLoginId: (id: number | null) => void;
  handleLoginForm: () => void;
  selectedLoginData: LoginItem;
}

const UpdateLoginFormSchema = (t: TFunction) =>
  Yup.object().shape({
    user: Yup.string()
      .min(3, t('minCharacters', { number: 3 }))
      .max(30, t('maxCharacters', { number: 30 }))
      .required(t('required')),
  });

const UpdateLoginForm = ({
  selectedLoginData,
  handleLoginForm,
  handleSelectedLoginId,
}: UpdateLoginProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const [scheme, setScheme] = useState<Yup.AnyObjectSchema>(UpdateLoginFormSchema(t));

  const { mutate: updateLoginMutation } = useMutation(updateLogin());

  const initialValues: UpdateLoginFormValuesProps = {
    user: selectedLoginData?.user ?? '',
  };

  useEffect(() => {
    async function onLanguageChanged() {
      setScheme(UpdateLoginFormSchema(t));
    }

    i18n.on('languageChanged', onLanguageChanged);

    return () => {
      i18n.off('languageChanged', onLanguageChanged);
    };
  }, [i18n, t, UpdateLoginFormSchema]);

  const handleSubmit = (data: UpdateLoginFormValuesProps) => {
    updateLoginMutation({ loginId: selectedLoginData?.loginId, user: data.user });
    handleSelectedLoginId(null);
    handleLoginForm();
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={scheme}>
      {formikProps => (
        <Form>
          <NewGroupWrapper isMobile={isMobile}>
            <StyledInputFormField
              width={isMobile ? '100%' : '312px'}
              name={'user'}
              label={t('loginName')}
              placeholder={t('addName')}
              noLabel
              inputSize="md"
              type="text"
              autoComplete={'off'}
            />
          </NewGroupWrapper>
          <ButtonWrapper isMobile={isMobile}>
            {!isMobile && (
              <Button secondary onClick={handleLoginForm}>
                {t('goBack')}
              </Button>
            )}
            <Button type={'submit'} primary fillWidth={isMobile} disabled={!formikProps.dirty}>
              {t('editLogin')}
            </Button>
          </ButtonWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateLoginForm;
