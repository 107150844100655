import React, { useMemo } from 'react';
import { ThemeProvider as OriginalThemeProvider } from 'styled-components/macro';

import { useAppContext } from '../utils/appContext';
import { Modes, themes } from './themes';

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { darkMode } = useAppContext();
  const isWW = process.env.REACT_APP_THEME === 'ww';

  const theme = useMemo(
    () => ({
      ...(isWW ? themes[darkMode ? Modes.WW_DARK : Modes.WW_LIGHT] : themes[Modes.SIEHS]),
      darkMode,
    }),
    [darkMode],
  );

  return <OriginalThemeProvider theme={theme}>{children}</OriginalThemeProvider>;
};
