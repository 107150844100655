import NotificationIcon from 'assets/icons/notification-chat-icon.png';
import chatSound from 'assets/sounds/chat-sound.wav';
import i18next from 'i18next';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { IsReadDot } from '../../../../modules/driver/components/ChatListItem';
import { UnreadStatisticsResponseDto } from '../../../../modules/driver/dtos';
import { getUnreadStatistics } from '../../../../modules/driver/queries';
import { routes } from '../../../../navigation/routes';
import desktopNotification from '../../../../utils/desktopNotification';
import { isDriverStatusAccount } from '../../../../utils/isDriverStatusAccount';
import { ChatItemWrapper } from '../../style';

interface ITopbarChatItemProps {
  name: string;
}

/**
 * This function is used to format notification content
 * @param response
 * @param messagesCount
 */
const getMessageContent = (
  response: UnreadStatisticsResponseDto,
  messagesCount: number,
): { title: string; options: { body: string } } | null => {
  switch (messagesCount) {
    case 1: {
      const messageItem = response?.UnreadMessages[0];
      const lastMessage = messageItem?.Message?.[0];
      if (!lastMessage) return null;

      return {
        title: `${i18next.t('newUnreadMessageTitle')} ${messageItem.Account.account.name}`,
        options: { body: lastMessage?.content },
      };
    }

    default: {
      return {
        title: i18next.t('newUnreadMessagesTitle'),
        options: {
          body: i18next.t('newUnreadMessagesBody'),
        },
      };
    }
  }
};

/**
 * Plays sound notification
 */
const playSound = () => {
  const audio = new Audio(chatSound);
  audio.play();
};

const TopbarChatItem: React.FC<ITopbarChatItemProps> = ({ name }) => {
  const navigate = useNavigate();

  /**
   * This function is used to display notification for unread messages
   * @param response
   */
  const handleDisplayNotification = (response: UnreadStatisticsResponseDto): void => {
    const messagesCount = response.UnreadMessages?.length ?? 0;
    if (messagesCount === 0) return;

    const messageContent = getMessageContent(response, messagesCount);
    if (!messageContent) return;

    playSound();
    desktopNotification(
      messageContent.title,
      { ...messageContent.options, icon: NotificationIcon },
      () => navigate(routes.chat),
    );
  };

  const { data: statistics } = useQuery(
    getUnreadStatistics({
      refetchInterval: 15000,
      enabled: isDriverStatusAccount(),
      onSuccess: data => handleDisplayNotification(data),
    }),
  );

  return (
    <>
      {/* <--- renders non-read symbol ---> */}
      {!!statistics?.Statistics && (
        <ChatItemWrapper>
          {name} ({statistics?.Statistics?.count})
          <IsReadDot />
        </ChatItemWrapper>
      )}

      {!statistics?.Statistics && name}
    </>
  );
};

export default TopbarChatItem;
